import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UriConfig } from "src/app/app.config";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { AuthService } from "src/app/core/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class FrameSinginService {

  role = 'B2C';

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) { }

  singin(token: string) {
    return this.http.post(this.uriConfig.frameSingin, {}, {headers: {
      'Authorization': `Bearer ${token}`
    }}).pipe(
      map(data => this.processLoggedUser(data))
    )
  }

  private processLoggedUser(data) {
    //to have ability test deeplink without integration
    this.authService.isFrameIntegration = true;
    this.localStorageService.setItem('role', this.role);
    this.localStorageService.setToken(data.accessToken);
    this.authService.processCurrentUser();
    this.authService.processIsRestrictedUser();
    return data;
  }

  logout() {
    this.localStorageService.removeAccessToken();
  }

}
