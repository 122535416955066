import { api } from "../assets/config/api.config";

export const environment = {
  production: false,
  name: "development",
  apiUrl: api.dev,
  version: "1678",
  analyticId: 'G-637X1VZ7GJ',
  sentryDsn: '',
  googleId: '290397902038-cjgdc9m34k0rg1t473hmvef2prdu1ihf.apps.googleusercontent.com',
  facebookId: '1177230173206336',
  isLocal: false
};
