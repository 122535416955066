import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationModalTypeEnum } from '../../enums/ConfirmationModalTypeEnum';
import { ConfirmationModalDetails } from "../../models/ConfirmationModalDetails";
import { signInPopupButtonsActions } from "../../enums/signInPopupButtonsAction";
import { ModalWindowService } from "../../services/modal-window.service";

@Component({
  selector: 'hun-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalDetails,
    private modalWindowService: ModalWindowService
  ) {
  }

  okButtonClick() {
    switch (this.data.modalType) {
      case ConfirmationModalTypeEnum.signInV3:
        this.dialogRef.close(signInPopupButtonsActions.login);
        break
      case ConfirmationModalTypeEnum.restrictedSegmentUserV3:
        window.open(this.data.okButtonLink);
        break;
      default:
        this.dialogRef.close(true);
    }
    this.modalWindowService.isAnyModalOpened.next(false);
  }

  cancelButtonClick() {
    switch (this.data.modalType) {
      case ConfirmationModalTypeEnum.signInV3:
        this.dialogRef.close(signInPopupButtonsActions.register);
        break
      default:
        this.dialogRef.close(false);
    }
    this.modalWindowService.isAnyModalOpened.next(false);

  }

}
