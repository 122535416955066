<hun-base-modal [_modalType]="data.modalType">
  <ng-container header>
    <img
      [src]="data.image">
  </ng-container>
  <ng-container title>
    {{ data.header }}
  </ng-container>
  <ng-container second-title>
    {{ data.secondHeader }}
  </ng-container>
  <ng-container desctiption>
    {{ data.description }}
  </ng-container>
  <ng-container additional-content>
    {{ data.content }}
  </ng-container>
  <ng-container full-width-modal-area>
    <hun-two-buttons-confirmation-modal
      [ngClass]="{'vertical-buttons': data.isVerticalButton}"
      [isVertical]="data.isVerticalButton"
      [cancelButtonTitle]="data.labelCancel"
      [confirmButtonTitle]="data.labelOk"
      [cancelButtonColor]="data.colorCancel"
      [confirmButtonColor]="data.colorOk"
      [confirmButtonBackgroundColor]="data.confirmButtonBackgroundColor"
      [confirmButtonBorderColor]="data.confirmButtonBorderColor"
      [cancelButtonBackgroundColor]="data.cancelButtonBackgroundColor"
      [cancelButtonBorderColor]="data.cancelButtonBorderColor"
      [singleButton]="data.singleButton"
      (confirmClick)="okButtonClick()"
      (cancelClick)="cancelButtonClick()"
    >
    </hun-two-buttons-confirmation-modal>
  </ng-container>
</hun-base-modal>
