import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentLocale$ = new BehaviorSubject<string>('en');

  constructor(
  ) {
  }

  setLocale(locale: string) {
    this.currentLocale$.next(locale);
  }
}
