<hun-main-modal *ngIf="(cmsContentService.cmsContent$ | async) as cmsContentConfig"
                class="info-popup"
                [ngClass]="{'mobile-info-popup': !(isDesktopService.isDesktop$ | async)}"
>
  <hun-modal-header header>
    <ng-container *ngIf="selectedOption$ | async">
      {{(selectedOption$ | async).label}}
    </ng-container>
  </hun-modal-header>
  <div body>
    <hun-info-modal-filter
      class="info-filter info-gradient"
      [ngClass]="{
      'info-gradient-right': !(isDesktopService.isDesktop$ | async) && (showRightGradient$ | async),
      'info-gradient-left': (showLeftGradient$ | async) && !(isDesktopService.isDesktop$ | async)}"
      *ngIf="(menuFilterOptions$ | async) as filterOptions"
      [filterOptions]="filterOptions"
      (selectedFilterOption)="onSelectFilterOption($event, filterOptions)">
    </hun-info-modal-filter>
    <ng-container  *ngIf="(selectedOption$ | async) as selectedOption">
      <div class="info-content-container" [ngClass]="{faq: selectedOption.isFAQ, slider: selectedOption.isSlider}">
        <ng-container *ngIf="!selectedOption.isSlider; else slider">
          <div class="info-content-header">{{selectedOption.expandedLabel}}</div>
          <ng-container>
            <div class="content" *ngIf="!selectedOption.isFAQ">
              <div class="list">
                  <div [innerHTML]="selectedOption.value"></div>
              </div>
            </div>
            <div class="content" *ngIf="selectedOption.isFAQ">
              <hun-items-expansion-pannel [faqContent]="selectedOption.value.items ? selectedOption.value.items : selectedOption.value"></hun-items-expansion-pannel>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #slider>
          <hun-info-modal-slider [slides]="selectedOption.value.slides"></hun-info-modal-slider>
        </ng-template>
      </div>
    </ng-container>
  </div>
</hun-main-modal>
