import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnlyFrameAccessGuard } from './core/guards/only-frame-access.guard';
import { PreviewComponent } from "./preview/preview.component";
import { FrameAccessGuard } from "./core/guards/frame-access.guard";
import { canMatchPrediction, canMatchStreak } from "./core/guards/streak.guard";
import {LockComponent} from "./app-common/components/lock/lock.component";
import {AllowAuthNextStep} from "./core/guards/allow-auth-next-step.guard";
import {AllowManualSignInGuard} from "./core/guards/allow-manual-sign-in.guard";

const routes: Routes = [
  {
    path: 'restorePassword',
    canActivate: [AllowManualSignInGuard],
    canMatch: [AllowAuthNextStep],
    loadChildren: () => import('./app-common/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'auth',
    canActivate: [AllowManualSignInGuard],
    loadChildren: () => import('./app-common/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path:'',
    canActivate: [OnlyFrameAccessGuard],
    children: [

      {
        path: 'lock',
        component: LockComponent
      },
      {
        path: 'preview',
        component: PreviewComponent
      },
      {
        path: 'frame',
        canActivate: [FrameAccessGuard],
        children: [
          {
            path: 'streak',
            canMatch: [canMatchStreak],
            loadChildren: () => import('./frame-streak/frame-streak.module').then((m) => m.FrameStreakModule),
          },
          {
            path: '',
            canMatch: [canMatchPrediction],
            loadChildren: () => import('./frame-v3/frame-v3.module').then((m) => m.FrameV3Module),
          },

        ]
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'frame/landing'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
