import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from "../services/snack-bar.service";
import { CmsContentService } from "../services/cms-content.service";
import { TenantService } from "../../app-common/services/tenant.services";
import { Router } from "@angular/router";
import {GameService} from "../services/game.service";

export enum CustomMessagesEndpointsEnum {
   ['/answers/submit'] = "iframe-v3-round-close-BE-error",
   ['/auth/refresh'] = "iframe-v3-auth-refresh-BE-error"
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackBarService,
              private cmsContentService: CmsContentService,
              private tenantService: TenantService,
              private router: Router,
              private gameService: GameService
              ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((this.tenantService.isSuperbetPromo$.value || window.location.href.includes('test')) && error.status === 401) {
          this.router.navigate(['auth/sign-in']);
        } else if (error.error?.path.includes('/answers/submit')) {
          const targetUrl = this.tenantService.isStreak$.value ? 'frame/streak/home' : 'frame/landing';
          this.gameService.reloadRound();
          this.router.navigate([targetUrl]);
        } else if (error.status !== 403) {
          let errorMessage = this.getErrorMessage(error);
          this.showErrorMessage(errorMessage, error);
        }

        return throwError(error);
      })
    );
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    if (error.status === 500) {
      return 'Server error';
    } else if (CustomMessagesEndpointsEnum[error.error?.path] && this.cmsContentService.cmsContent$.value['text_content'][CustomMessagesEndpointsEnum[error.error?.path]]) {
      return this.cmsContentService.cmsContent$.value['text_content'][CustomMessagesEndpointsEnum[error.error?.path]];
    } else {
      return this.extractErrorMessage(error.error);
    }
  }

  private extractErrorMessage(error: any): string {
    let errorMessage = 'An error occurred. Please try again later.';
    if (error) {
      if (typeof error === 'string') {
        errorMessage = error;
      } else if (error.message) {
        if (typeof error.message === 'string') {
          errorMessage = error.message;
        } else if (Array.isArray(error.message)) {
          errorMessage = error.message.join(', ');
        }
      }
    }
    return errorMessage;
  }

  private showErrorMessage(errorMessage: string, error: HttpErrorResponse): void {
    if (errorMessage) {
      this.snackBarService.showSnackBar(errorMessage);
    } else {
      console.error('Unknown error occurred:', error);
    }
  }}
