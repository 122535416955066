import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import {finalize, Observable, timer} from 'rxjs';
import {LoaderControllerService} from "../services/loader-controller.service";
import {filter, take, tap} from "rxjs/operators";

@Injectable()
export class PlatformLoadingInterceptor implements HttpInterceptor {

	private activeRequests = 0;

	private isFirstLoad = true;

	private skipInitRequests = ['check-unauth', 'check-modifying'];

	constructor(private loaderControllerService: LoaderControllerService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.activeRequests++;

		return next.handle(request).pipe(
			finalize(() => {
				if (this.isFirstLoad) {
					this.activeRequests--;

					timer(100).pipe(
						filter(() => {
							return this.activeRequests === 0 && !this.skipInitRequests.find(requestPath => request.url.includes(requestPath));
						}),
						take(1),
						tap(() => {
							this.isFirstLoad = false;
							this.loaderControllerService.isLoading$.next(false);
						}),
					).subscribe();
				}
			})
		);
	}
}
