<ng-container *ngIf="maintenanceControlService.isMaintenance$ | async">
  <ng-container *ngIf="isCocpitDataReceived(cmsContentService.cmsContent$ | async); else defaultLockPage">
    <div
      class="content"
      [ngClass]="{'mobile': !(isDesktopService.isDesktop$ | async)}"
      *ngIf="(cmsContentService.cmsContent$ | async) as cmsContentConfig">
      <ng-container *ngIf="tenantService.isStreak$ | async; else predictionLogo">
        <img [src]="cmsContentConfig['image_content']['iframe-v3-streak-logo']">
      </ng-container>
      <ng-template #predictionLogo>
        <ng-container *ngIf="!(tenantService.isNapoleon$ | async) && isDesktopService.isDesktop$ | async; else default">
          <img class="desctop" [src]="cmsContentConfig['image_content']['iframe-v3-superprono-logo']">
        </ng-container>
        <ng-template #default>
          <img [src]="cmsContentConfig['image_content']['iframe-v3-superprono-logo-mobile']">
        </ng-template>
      </ng-template>

      <div class="under-construction-text">
        {{cmsContentConfig['text_content']['iframe-v3-under-construction']}}
      </div>
    </div>
  </ng-container>

  <ng-template #defaultLockPage>
    <div
      class="content"
      [ngClass]="{'mobile': !(isDesktopService.isDesktop$ | async)}">
      <img class="desctop" src="../../../../assets/image/frame-v3/Dark_None.svg">
    </div>
  </ng-template>
</ng-container>

