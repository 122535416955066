import {Injectable} from '@angular/core';
import {OutsideMessageServices} from "./outside-messages.services";
import {AnalyticsEventEnum} from "../enums/AnalyticsEventEnum";

@Injectable({
	providedIn: 'root'
})
export class GAnalyticsService {
	//NOTE 2258 Tracking changes need rollback (all in this file as on prod + add session-storage.service.ts )


	constructor(
		private outsideMessageServices: OutsideMessageServices
	) {
	}

	toPlay(parameters) {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.PLAY, parameters});
	}

	submitGame(parameters) {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.SUBMIT_GAME, parameters});
	}

	selectAnswer(parameters) {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.ANSWER_CLICK, parameters});
	}

	bannerClick(parameters) {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.BANNER_CLICK, parameters});
	}

  bannerImpression(parameters) {
    this.processEventPlatformSensitive({type: AnalyticsEventEnum.BANNER_IMPRESSION, parameters});
  }

	frameLoaded() {
		this.processEventPlatformSensitive({type: AnalyticsEventEnum.FRAME_LOADED});
	}

	processEventPlatformSensitive(parametersBody) {
		if ((window as any)?.android) {
			this.outsideMessageServices.sendMessageToAndroidParent(JSON.stringify(parametersBody));
		} else if ((window as any)?.webkit?.messageHandlers?.gameHandler) {
			this.outsideMessageServices.sendMessageToIOSParent(JSON.stringify(parametersBody));
		} else {
      if (parametersBody.type) {
        parametersBody.event = parametersBody.type;
        delete parametersBody.type;
      }
			this.sendEvent(parametersBody);
		}
	}

	sendEvent(data) {
		if (window && (window as any).dataLayer) {
			(window as any).dataLayer.push(data);
		}
	}
}
