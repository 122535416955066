import { Injectable } from '@angular/core';
import { UriConfig } from 'src/app/app.config';
import {HttpClient, HttpParams} from "@angular/common/http";
import {LocalStorageService} from "./local-storage.service";
import { catchError, switchMap } from "rxjs/operators";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {SettingsConfigDetail} from "../../app-common/models/SettingsConfigDetail";
import { SettingAvailability } from 'src/app/app-common/enums/settingAvailability';
import { MaintenanceControlService } from "./maintenance-control.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class SaasSetingsService {

  settingConfigArray$ = new BehaviorSubject<SettingsConfigDetail[]>([]);

  isMultipleQuestionsOn1Page$: Observable<boolean>;

  isHideQuestionText$: Observable<boolean>;

  isShowUpsell$: Observable<boolean>;

  currencyAfterPrize$: Observable<boolean>;

  amountSeparator$: Observable<string>;


  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private localStorageService: LocalStorageService,
    private maintenanceControlService: MaintenanceControlService,
    private authService: AuthService
  ) { }

  getSettingsList(): Observable<SettingsConfigDetail[]> {
    let params = new HttpParams();
    params = params.append('availability', SettingAvailability.B2C);
    params = params.append('availability', SettingAvailability.BOTH);

    return this.http.get<SettingsConfigDetail[]>(this.uriConfig.settings + '/b2c', { params });
  }


  loadSettings(hashResponse:  string): Observable<SettingsConfigDetail[]> {
    const hash = this.localStorageService.getSettingsHash();
    const savedSettingConfig = this.localStorageService.getSettingsConfig();
    if (savedSettingConfig && hash === hashResponse) {
      this.processSetting(savedSettingConfig);
      return of(savedSettingConfig);
    } else {
      return this.getSettingsList().pipe(
        switchMap((settings: SettingsConfigDetail[]) => {
          this.localStorageService.setSettingsHash(hashResponse);
          this.localStorageService.setSettingsConfig(settings)
          this.processSetting(settings);
          return of(settings);
        }),
        catchError(error => {
          return throwError(error)
        })
      );
    }
  }

  processSetting(config) {
    this.settingConfigArray$.next(config);
    this.checkMaintenanceMode(config);
    this.checkBannedUserInform(config);
    this.checkQuestionView(config);
    this.checkQuestionTextVisibility(config);
    this.checkUpsellVisibility(config);
    this.getCurrencyPlacement(config);
    this.getAmountSeparator(config);
  }

  checkMaintenanceMode(settings) {
    const isLock = settings?.find(setting => setting.key === 'maintenance_mode')?.value === 'true';
    this.maintenanceControlService.isMaintenance$.next(isLock);
    this.maintenanceControlService.subscribeMaintenanceCheck();
  }

  checkBannedUserInform(settings) {
    const areBannedUserAllowedPlayGame = settings?.find(setting => setting.key === 'are_banned_users_allowed_play_game')?.value === 'true';
    this.authService.areBannedUserAllowedPlayGame$.next(areBannedUserAllowedPlayGame);
  }

  checkQuestionView(config) {
    this.isMultipleQuestionsOn1Page$ = of(config.find(setting => setting.key === 'multiple_questions_enabled')?.value === 'true');
  }

  checkQuestionTextVisibility(config) {
    this.isHideQuestionText$ = of(config.find(setting => setting.key === 'is_question_text_not_required')?.value === 'true');
  }

  checkUpsellVisibility(config) {
    this.isShowUpsell$ = of(config.find(setting => setting.key === 'show_upsell')?.value === 'true');
  }

  getCurrencyPlacement(config) {
    this.currencyAfterPrize$ = of(config.find(setting => setting.key === 'currency_after_prize_amount')?.value === 'true');
  }

  getAmountSeparator(config) {
    this.amountSeparator$ = of(config.find(setting => setting.key === 'prize_amount_separator')?.value || '.');
  }
}
