import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UserLeaderboard } from "../helpers/generateUserLeaderboard";

@Injectable({
	providedIn: "root"
})

export  class UserLeaderboardService {
	private userLeaderboardData$ = new BehaviorSubject(UserLeaderboard.generateDefaultUserLeaderboardData())

	setUserLeaderboardData(value: any, streakLevel: number, correctAnswersAmount) {
		const data = UserLeaderboard.generateUserLeaderboardData({...value, streakLevel, correctAnswersAmount});
		this.userLeaderboardData$.next(data);
	}

	getUserLeaderboard$() {
		return this.userLeaderboardData$.asObservable()
	}
}
