import { Injectable } from '@angular/core';
import { CmsContentService } from "../../core/services/cms-content.service";
import { ConfirmationModalTypeEnum } from "../enums/ConfirmationModalTypeEnum";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ConfirmationModalDetails } from "../models/ConfirmationModalDetails";

@Injectable({
  providedIn: 'root'
})
export class GenerateConfirmationModalConfigService {

  constructor(
    public cmsContentService: CmsContentService,
  ) { }

  generateConfig(modalType: ConfirmationModalTypeEnum): Observable<ConfirmationModalDetails> {
    return this.cmsContentService.cmsContent$.pipe(
      map(({text_content, image_content, colour_content}) => {
        switch (modalType) {
          case ConfirmationModalTypeEnum.addNameV3:
            return null;
          case ConfirmationModalTypeEnum.confirmationSubmitV3:
            return {
              labelOk: text_content['iframe-v3-confirm-selection-modal-confirm-button'],
              colorOk: colour_content['iframe-v3-confirm-selection-modal-ok-button-color'],
              labelCancel: text_content['iframe-v3-confirm-selection-modal-cancel-button'],
              colorCancel: colour_content['iframe-v3-confirm-selection-modal-cancel-button-color'],
              header: text_content['iframe-v3-confirm-selection-modal-header'],
              description: text_content['iframe-v3-confirm-selection-modal-description'],
              isVerticalButton: true,
              confirmButtonBackgroundColor: colour_content['iframe-v3-confirm-button-background-color'],
              cancelButtonBackgroundColor: colour_content['iframe-v3-cancel-button-background-color'],
              image: image_content['iframe-v3-confirm-popup-image'],
              cancelButtonBorderColor: colour_content['iframe-v3-cancel-button-border-color'],
              modalType
            }
          case ConfirmationModalTypeEnum.confirmationExitV3:
            return {
              labelOk: text_content['iframe-v3-confirm-exit-modal-confirm-button'],
              colorOk: colour_content['iframe-v3-confirm-exit-modal-ok-button-color'],
              labelCancel: text_content['iframe-v3-confirm-exit-modal-cancel-button'],
              colorCancel: colour_content['iframe-v3-confirm-exit-modal-cancel-button-color'],
              header: text_content['iframe-v3-confirm-exit-modal-header'],
              description: text_content['iframe-v3-confirm-exit-modal-description'],
              isVerticalButton: true,
              confirmButtonBackgroundColor: colour_content['iframe-v3-confirm-exit-button-background-color'],
              cancelButtonBackgroundColor: colour_content['iframe-v3-cancel-exit-button-background-color'],
              image: image_content['iframe-v3-confirm-exit-popup-image'],
              cancelButtonBorderColor: colour_content['iframe-v3-cancel-exit-button-border-color'],
              modalType
            }
          case ConfirmationModalTypeEnum.signInV3:
            return {
              labelOk: text_content['iframe-v3-signin-modal-confirm-button'],
              colorOk: colour_content['iframe-v3-singin-modal-ok-button-color'],
              labelCancel: text_content['iframe-v3-singin-modal-cancel-button'],
              colorCancel: colour_content['iframe-v3-singin-modal-cancel-button-color'],
              header: text_content['iframe-v3-singin-modal-header'],
              description: text_content['iframe-v3-singin-modal-description'],
              isVerticalButton: true,
              confirmButtonBackgroundColor: colour_content['iframe-v3-singin-button-background-color'],
              cancelButtonBackgroundColor: colour_content['iframe-v3-singin-cancel-button-background-color'],
              image: image_content['iframe-v3-singin-popup-image'],
              cancelButtonBorderColor: colour_content['iframe-v3-singin-cancel-button-border-color'],
              modalType
            }
          case ConfirmationModalTypeEnum.bannedUserV3:
            return {
              labelCancel: text_content['iframe-v3-banned-user-modal-cancel-button'],
              colorCancel: colour_content['iframe-v3-banned-user-modal-cancel-button-color'],
              header: text_content['iframe-v3-banned-user-modal-header'],
              description: text_content['iframe-v3-banned-user-modal-description'],
              image: image_content['iframe-v3-banned-user-popup-icon'],
              cancelButtonBorderColor: colour_content['iframe-v3-banned-user-cancel-button-border-color'],
              isVerticalButton: true,
              modalType,
              disableClose: true,
              singleButton: true
            }
          case ConfirmationModalTypeEnum.prizeV3:
            return {
              labelCancel: text_content['iframe-v3-prize-modal-button-text'],
              colorCancel: colour_content['iframe-v3-prize-modal-button-text-color'],
              isVerticalButton: false,
              header: text_content['iframe-v3-prize-modal-header'],
              description: text_content['iframe-v3-prize-modal-description'],
              modalType,
              image: image_content['iframe-v3-info-modal-close-icon'],
              disableClose: false,
              hideDivider: true,
              singleButton: true,
              cancelButtonBackgroundColor: colour_content['iframe-v3-prize-modal-button-color'],
            }
          case ConfirmationModalTypeEnum.restrictedSegmentUserV3:
            return {
              labelOk: text_content['iframe-v3-restricted-segment-user-modal-ok-button'],
              colorOk: colour_content['iframe-v3-restricted-segment-user-modal-ok-button-text-color'],
              labelCancel: text_content['iframe-v3-restricted-segment-user-modal-cancel-button'],
              colorCancel: colour_content['iframe-v3-restricted-segment-user-modal-cancel-button-color'],
              header: text_content['iframe-v3-restricted-segment-user-modal-header'],
              description: text_content['iframe-v3-restricted-segment-user-modal-description'],
              image: image_content['iframe-v3-restricted-segment-user-popup-icon'],
              cancelButtonBorderColor: colour_content['iframe-v3-restricted-segment-user-cancel-button-border-color'],
              confirmButtonBackgroundColor: colour_content['iframe-v3-restricted-segment-user-modal-ok-button'],
              isVerticalButton: true,
              modalType,
              disableClose: true,
              singleButton: false,
              okButtonLink: text_content['iframe-v3-restricted-segment-user-modal-ok-button-link']
            }
        }
      })
    )
  }
}
