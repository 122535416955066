import { CanActivateFn, Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { inject } from "@angular/core";
import { TenantService } from "../../app-common/services/tenant.services";

export const AllowManualSignInGuard: CanActivateFn = () => {
	const router = inject(Router);
  const tenantService = inject(TenantService);
  const isTestTenant = window.location.href.includes('test');

	if (environment.production && !isTestTenant && !tenantService.isSuperbetPromo$.value) {
		router.navigate(['frame'], {replaceUrl: true});
		return false;
	}

	return true;
};
