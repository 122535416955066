<div class="modal">
    <div class="title">
        <div>{{ data.header }}</div>
        <img
                class="close-btn"
                (click)="closeModal()"
                [src]="data.image">
    </div>
    <ng-container *ngIf="data.content; else defaultPriceTemplate">
        <div class="desctiption content">
            <div [innerHtml]="data.content"></div>
        </div>
    </ng-container>
    <div class="close-prize-modal-button">
        <hun-two-buttons-confirmation-modal
                [cancelButtonTitle]="data.labelCancel"
                [cancelButtonColor]="data.colorCancel"
                [cancelButtonBackgroundColor]="data.cancelButtonBackgroundColor"
                [singleButton]="data.singleButton"
                (cancelClick)="closeModal()"
        >
        </hun-two-buttons-confirmation-modal>
    </div>
</div>

<ng-template #defaultPriceTemplate>
    <ng-container *ngIf="cmsContentService.cmsContent$ | async as cmsContent">
        <div class="desctiption">
            <div
                    class="prize-description-wrapper prize-space-wrapper"
                    [innerHTML]="getDescription(cmsContent['text_content']) | async">
            </div>
            <div class="amount-description">
                {{ cmsContent['text_content']['iframe-v3-prize-modal-amount-description'] }}
            </div>
            <ul>
                <ng-container *ngFor="let prize of data.body.prizeList">
                    <li *ngIf="prize.assignment === 'SPLIT' && cmsContent['text_content']['iframe-v3-prize-modal-shared-prize-description']"
                        style="list-style-type: none;">
                        {{ cmsContent['text_content']['iframe-v3-prize-modal-shared-prize-description'] }}
                    </li>
                    <li>
          <span class="prize-amount-wrapper prize-space-wrapper">
          {{
              prizeAmountService.getSinglePrizeAmount(
                prize.prize,
                cmsContent['text_content']['iframe-v3-currency'],
                prize.type === PrizeTypeEnum.CASH_POINTS
              ) | async
              }}
          </span>–
                        {{ prize.correctCount }}/{{ data.body.totalCount }}
                    </li>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</ng-template>