import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TenantService } from "../../app-common/services/tenant.services";


export const canMatchStreak = (): Observable<boolean> | Promise<boolean> | boolean => {
	const tenantService = inject(TenantService);
	const router = inject(Router);
	return tenantService.isStreak$.pipe(
		take(1),
		map(res => {
			if (!res) {
				router.navigateByUrl('/frame/landing');
				return false;
			}
			return true;
		})
	);
};

export const canMatchPrediction = (): Observable<boolean> | Promise<boolean> | boolean => {
	const tenantService = inject(TenantService);
	const router = inject(Router);

	return tenantService.isStreak$.pipe(
		take(1),
		map(res => {
			if (res) {
				router.navigateByUrl('/frame/streak');
				return false;
			}
			return true;
		})
	);
};
