import { Injectable } from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import { InfoModalTabModel } from "../models/InfoModalTabModel";
import { UriConfig } from "../../app.config";
import {LanguageService} from "../../core/services/language.service";

@Injectable({providedIn: "root"})
export class PrizeModalService {
	constructor(
		private http: HttpClient,
		private uriConfig: UriConfig,
		private languageService: LanguageService,
	) {
	}

	getRoundPrizePopup(id: number) {
		const locale = this.languageService.currentLocale$.value;
		const params = new HttpParams().set('locale', locale)
		return this.http.get<InfoModalTabModel>(this.uriConfig.infoModalTabs + `/prize-popups/${id.toString()}`, locale ? {params} : {})
	}
}
