import { ConfirmationModalTypeEnum } from "../enums/ConfirmationModalTypeEnum";

export class ConfirmationModalDetails {
  labelOk?: string
  colorOk?: string
  labelCancel?: string
  colorCancel?: string
  isVerticalButton: boolean
  image?: string
  header: string
  secondHeader?: string
  description: string
  modalType: ConfirmationModalTypeEnum
  disableClose?: boolean
  hideDivider?: boolean
  hideBackground?: boolean
  singleButton?: boolean
  cancelButtonBackgroundColor?: string
  cancelButtonBorderColor?: string
  confirmButtonBorderColor?: string
  confirmButtonBackgroundColor?: string
  content?: string
  body?: any
  okButtonLink?: string
}
