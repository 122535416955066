import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UriConfig } from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class HashService {

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  getHash() {
    return this.http.get<{contentHash: string; settingsHash: string}>(this.uriConfig.checkModifying);
  }

}
