<div class="header"
     [ngClass]="{
     'banned-user': restrictedAccessModal}">
  <ng-content select="[header]"></ng-content>
</div>
<div class="modal" [ngClass]="{ 'banned-user': restrictedAccessModal}">
  <div class="title"
       [ngStyle]="{'max-width': restrictedAccessModal || ConfirmationModalTypeEnum.addNameV3 ? '' : '288px'}">
    <ng-content select="[title]"></ng-content>
  </div>
  <div class="description">
    <ng-content select="[desctiption]"></ng-content>
  </div>
  <div class="additional-content">
    <ng-content select="[additional-content]"></ng-content>
  </div>
  <ng-content select="[actions-area]"></ng-content>
</div>
<div class="full-width-modal-area">
  <ng-content select="[full-width-modal-area]"></ng-content>
</div>

