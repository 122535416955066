import { Injectable } from '@angular/core';
import { Platform } from "@angular/cdk/platform";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PlatformDetectorService {

  isSafari$: Observable<boolean>;

  isIOS$: Observable<boolean>;

  constructor(
    private platform: Platform
  ) {
    this.isSafari$ = of(this.platform.SAFARI);
    this.isIOS$ = of(this.platform.IOS);
  }
}
