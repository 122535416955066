import { GameStatus } from "../enums/game-status";
import { GameEventDetails } from "./GameEventDetails";

export class CurrentRoundData {
  id: number
  openDate: string
  closeDate: string
  hasPlayed: boolean
  viewedResults: boolean
  status: GameStatus
  answersReviewed: boolean;
}

export class AnswerStatus {
  roundId: number
  status: string
}

export class UserLeaderboardData {
  position: number
  points: number
  cashPoints: number
  tierName: string
  tierColor: string
  pointsToClosestTier: number
  positionToClosestTier: number
  closestTierName: string
}

export class StreakDetailModel {
  id: number
  name: string
  status: GameStatus
  openDate: string
  closeDate: string
  prize: number
  correctAnswersNeeded: number
  currentRoundData: CurrentRoundData
  levelsAmount: number
  streakLevel: number
  points: number
  answersStatus: AnswerStatus[]
  userLeaderboardData: UserLeaderboardData
  gameButtonText?: string
  gameButtonColor?: string
  gameButtonTextColor?: string
  isStreakOpened?: boolean
  sportEvent?: GameEventDetails
  nextRoundOpensAt: string
  viewedStreakLvlResetMessage?: boolean;
  viewedPreviousRoundResult?: boolean
}
