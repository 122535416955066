import { Component } from '@angular/core';
import { CmsContentService } from 'src/app/core/services/cms-content.service';
import { MaintenanceControlService } from "../../../core/services/maintenance-control.service";
import { IsDesktopService } from '../../services/is-desktop.services';
import { TenantService } from '../../services/tenant.services';

@Component({
  selector: 'hun-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent {

  constructor(
    public cmsContentService: CmsContentService,
    public isDesktopService: IsDesktopService,
    public tenantService: TenantService,
    public maintenanceControlService: MaintenanceControlService
  ) {}

  isCocpitDataReceived(config) {
    if (!config || !Object.keys(config).length) return false;
    return !(!config.colour_content || !config.text_content || !config.image_content);

  }

}
