import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from "@angular/router";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {UriConfig} from "./app.config";
import {SnackBarService} from "./core/services/snack-bar.service";
import {AuthGuard} from "./core/guards/auth.guard";
import {JwtTokenInterceptor} from "./core/interseptors/jwt-token.interceptor";
import { initializeAppFactory } from './initializeAppFactory';
import { AppInitializeService } from './core/services/app-initialize.service';
import { OnlyFrameAccessGuard } from './core/guards/only-frame-access.guard';
import { ErrorHandlerInterceptor } from "./core/interseptors/error-handler.interceptor";
import { PreviewComponent } from './preview/preview.component';
import { ErrorEmulationInterceptor } from './core/interseptors/error-emulation.interceptor';
import {
  MAT_BOTTOM_SHEET_DATA,
  MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
  MatBottomSheet
} from "@angular/material/bottom-sheet";
import { LottieModule } from "ngx-lottie";
import player from 'lottie-web';
import {MatDialogModule} from "@angular/material/dialog";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {PlatformLoadingInterceptor} from "./core/interseptors/platform-loading.interceptor";
import * as Sentry from "@sentry/angular";


// Function for ngx-lottie configuration
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent,
    PreviewComponent,
  ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatDialogModule,
		MatSnackBarModule,
		HttpClientModule,
		LottieModule.forRoot({player: playerFactory}),
		MatButtonModule,
  ],
  providers: [
    UriConfig,
    SnackBarService,
    OnlyFrameAccessGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformLoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    //uncomment if you need test error
    // { provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorEmulationInterceptor,
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitializeService],
      multi: true
    },
    {provide: MatBottomSheet},
    {provide: MAT_BOTTOM_SHEET_DATA, useValue: {}},
    {provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: {panelClass: 'frame-overlay-panel'}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
