import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import { UriConfig} from "../../app.config";
import { LanguageService } from "./language.service";

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
    private languageService: LanguageService
  ) { }

  submitAnswersScope(answers) {
    const currentLocale = this.languageService.currentLocale$.value;
    let params = new HttpParams();
    if (currentLocale) {
      params = params.append('locale', currentLocale)
    }
    return this.http.post(`${this.uriConfig.answers}/submit`, answers, {params});
  }
}
