import { Injectable } from '@angular/core';
import {SettingsConfigDetail} from "../../app-common/models/SettingsConfigDetail";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private TOKEN_KEY = 'auth-token';
  private USER_TOKEN_KEY = 'user-token';
  private SETTINGS_HASH = 'settings-hash';
  private CMS_CONTENT_HASH = 'cms-content-hash';
  private SETTINGS = 'settings';
  private CMS_CONTENT = 'cms-content';
  private LOCALE = 'locale';
  private ANSWERS = 'answers';
  private PREVIEW_ANSWERS = 'preview_answers';


  constructor() { }

  setItem(prop: string, value: any): void {
    localStorage.setItem(prop, JSON.stringify(value));
  }

  getItem(prop: string): any {
    return JSON.parse(localStorage.getItem(prop));
  }

  removeItem(prop: string) {
    localStorage.removeItem(prop);
  }

  setCmsContentHash(value: string) {
    this.setItem(this.CMS_CONTENT_HASH, value);
  }

  getCmsContentHash() {
    return this.getItem(this.CMS_CONTENT_HASH);
  }

  setCmsContent(value: any) {
    this.setItem(this.CMS_CONTENT, value);
  }

  getCmsContent():any {
    return this.getItem(this.CMS_CONTENT);
  }

  getToken(): string {
    return this.getItem(this.TOKEN_KEY)
  }

  setToken(token: string) {
    this.setItem(this.TOKEN_KEY, token)
  }

  removeAccessToken() {
    this.removeItem(this.TOKEN_KEY);
  }

  getDefaultUserTokenToken(): string {
    return this.getItem(this.USER_TOKEN_KEY)
  }

  setDefaultUserTokenToken(token) {
    this.setItem(this.USER_TOKEN_KEY, token)
  }


  removeDefaultUserTokenToken() {
    this.removeItem(this.USER_TOKEN_KEY)
  }

  getSettingsConfig() {
    return this.getItem(this.SETTINGS);
  }

  setSettingsConfig(config: SettingsConfigDetail[]) {
    this.setItem(this.SETTINGS, config)
  }

  getSettingsHash() {
    return this.getItem(this.SETTINGS_HASH);
  }

  setSettingsHash(hash: string) {
    this.setItem(this.SETTINGS_HASH, hash)
  }

  getLocale() {
    return this.getItem(this.LOCALE);
  }

  setLocale(locale) {
    this.setItem(this.LOCALE, locale);
  }

  getSelectedAnswers() {
    return this.getItem(this.ANSWERS);
  }

  saveSelectedAnswers(answers) {
    this.setItem(this.ANSWERS, answers);
  }

  deleteSelectedAnswers(){
    this.removeItem(this.ANSWERS);
  }

  getPreviewAnswers() {
    return this.getItem(this.PREVIEW_ANSWERS);
  }

  savePreviewAnswers(answers) {
    this.setItem(this.PREVIEW_ANSWERS, answers);
  }

  deletePreviewAnswers(){
    this.removeItem(this.PREVIEW_ANSWERS);
  }
}
