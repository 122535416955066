import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
Sentry.init({
  dsn: environment.sentryDsn,
  ignoreErrors: [
    "User with this nickname already exists",
    "Unauthorized",
    "Forbidden",
    "Cannot read properties of null (reading 'version')",
    "Failed to load CMS content",
  ],
  environment: environment.name,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
