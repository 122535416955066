import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError, switchMap, combineLatest } from 'rxjs';
import { FrameSinginService } from 'src/app/app-common/services/frame-singin.services';
import { AuthService } from '../services/auth.service';
import { CmsContentService } from "../services/cms-content.service";
import { GameService } from "../services/game.service";
import { AuthValidationService_v3 } from "../../app-common/services/auth-validation-service_v3.service";
import { OutsideMessageServices } from 'src/app/app-common/services/outside-messages.services';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  constructor(
    private frameSinginService: FrameSinginService,
    private authService: AuthService,
    private authValidationService: AuthValidationService_v3,
    private cmsContentService: CmsContentService,
    private gameService: GameService,
    private outsideMessageServices: OutsideMessageServices,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authorizationProcessing(request, next)
      .pipe(
        catchError(error => {
          return combineLatest([
            this.authValidationService.noauthUserAvailability$,
            this.cmsContentService.cmsContent$
          ]).pipe(
            switchMap(() => {
              if (
                error instanceof HttpErrorResponse &&
                error.status === 401
              ) {
                this.frameSinginService.logout();
                this.outsideMessageServices.sessionExpired();
              }
              return throwError(() => error);
            })
          )
        })
      );
  }

  private authorizationProcessing(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('Authorization') !== null) {
      return next.handle(request);
    } else {
      const token = this.authService.isPreviewUsesOnlyUserToken$.value ?
        this.getDefaultUser() :
        (this.gameService.isPreviewGame$.value ? this.getDefaultUser() : this.getCurrentUser());
      const authReq = token
        ? request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token
          },
        })
        : request;
      return next.handle(authReq);
    }
  }

  private getCurrentUser() {
    return this.authService.getCurrentUser();
  }

  private getDefaultUser() {
    return this.authService.getDefaultUser();
  }

}
