import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'hun-two-buttons-confirmation-modal',
  templateUrl: './two-buttons-confirmation-modal.component.html',
  styleUrls: ['./two-buttons-confirmation-modal.component.scss']
})
export class TwoButtonsConfirmationModalComponent {

  @Input() cancelButtonTitle: string;

  @Input() confirmButtonTitle: string;

  @Input() cancelButtonColor: string;

  @Input() cancelButtonBackgroundColor: string;

  @Input() cancelButtonBorderColor: string;

  @Input() confirmButtonColor: string;

  @Input() confirmButtonBorderColor: string;

  @Input() confirmButtonBackgroundColor: string;

  @Input() isVertical: boolean;

  @Input() singleButton: boolean;

  @Output() confirmClick = new EventEmitter();

  @Output() cancelClick = new EventEmitter();
}
