import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from "rxjs";
import { StreakFactory } from "../helpers/generateStreak";
import { StreakModel } from "../models/StreakModel";
import { HttpClient } from "@angular/common/http";
import { UriConfig } from "../../app.config";
import { RoundResultStatus } from "../enums/roundResultEnum";


@Injectable({
	providedIn: 'root'
})
export class StreakService {
	private roundStatusSubject$ = new BehaviorSubject<RoundResultStatus>(null);

	roundStatus$ = this.roundStatusSubject$.asObservable();

	private streak$ = new BehaviorSubject<StreakModel[]>([]);

	constructor(private http: HttpClient,
							private uriConfig: UriConfig,) {
	}

	setRoundStatus(status: RoundResultStatus) {
		this.roundStatusSubject$.next(status);
	}

	getRoundStatusValue() {
		return this.roundStatusSubject$.value
	}

	setStreak(value: any) {
		const streak = StreakFactory.generateStreak(value);
		this.streak$.next([...streak]);
	}

	getStreak$(): Observable<StreakModel[]> {
		return this.streak$.asObservable()
	}

	getActiveStreak$(): Observable<StreakModel> {
		return this.streak$.pipe(map(res => res.find(item => item.isActive)))
	}

	viewAnswer(roundId) {
		return this.http.patch<any>(`${this.uriConfig.rounds}/${roundId}/results-seen`, {})

	}

	loseProgressSeen(roundId) {
		return this.http.patch<any>(`${this.uriConfig.rounds}/${roundId}/reset-lvl-message-seen`, {})
	}
}
